import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import {
    FlexStartGap,
    FlexInline,
    QuestionContent,
    AccordionDetailsStyled,
    AccordionStyled,
    AccordionSummaryStyled,
} from '../../../../../common/styled/styled';
import { Modulebody } from '../OptionalModules';
import { Pagetab } from '../../../../../common/FormInputs/Common';
import { SETUP_SURVEY_TEXT } from '../../../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../../../common/colors';
import { commonThemes } from '../../../../../common/themes';
import CheckboxSvg from '../../../../../assets/img/Checkbox.svg';
import DisabledCheckboxSvg from '../../../../../assets/img/DisabledCheckbox.svg';
import arrowDown from '../../../../../assets/img/Small-down.svg';
import styled from 'styled-components';

const TeiQuestions = ({ teiData = [] }) => {
    const stylesComp = commonThemes();
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [tabId, setTabId] = React.useState(null);

    const { questions: questions } = React.useMemo(() => {
        if (teiData?.length) {
            const indexToUse = teiData.findIndex(({ sub_group_code }) => sub_group_code === tabId);
            const { sections = [], sub_group_code } = teiData[indexToUse] || {};
            return {
                questions: sections,
                indexToUse,
                sub_group_code,
            };
        }
        return {
            questions: [],
            indexToUse: 0,
        };
    }, [teiData, tabId]);

    React.useEffect(() => {
        if (tabId === null && teiData?.length) {
            const { sub_group_code } = teiData[0];
            setTabId(sub_group_code);
        }
        // eslint-disable-next-line
    }, [teiData, tabId]);

    const handleTab = newTabId => setTabId(newTabId);

    const allQuesNotSelected = questions.some(({ use_in_survey }) => !use_in_survey);
    const makeQuestionDisable = true;

    return (
        <Modulebody style={{ paddingTop: 0, paddingBottom: `${pxToVh(40)}vh` }} className="mainContent">
            <AccordionStyled paddingBottom={0.001} expanded={isExpanded}>
                <AccordionSummaryStyled
                    pdLeftIcon={4}
                    expandIcon={<img src={arrowDown} alt="arrow-down" />}
                    pdTop={0}
                    pdBottom={0}
                    width={'fit-content'}
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                >
                    <Typography className="actionClass" color={colorsCode.SecondaryDeepBlue500}>
                        {isExpanded ? 'HIDE QUESTIONS' : 'VIEW QUESTIONS'}
                    </Typography>
                </AccordionSummaryStyled>
                <AccordionDetailsStyled
                    style={{
                        paddingTop: 0,
                        borderBottom: 0,
                    }}
                >
                    <TabsWrapper className="tabDiv">
                        <FlexStartGap gap={pxToVw(16)}>
                            <div style={{ alignSelf: 'stretch' }}>
                                <FlexInline>
                                    {teiData.map(({ group_text, sub_group_code }) => {
                                        return (
                                            <Pagetab
                                                key={sub_group_code}
                                                active={tabId === sub_group_code}
                                                label={group_text}
                                                clickHandler={() => handleTab(sub_group_code)}
                                            />
                                        );
                                    })}
                                </FlexInline>
                            </div>
                        </FlexStartGap>
                    </TabsWrapper>
                    <QuestionContent textSize={14} lineHeight={20}>
                        <FormGroup>
                            <FormControlLabel
                                disabled
                                sx={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        disableRipple
                                        checked={!allQuesNotSelected}
                                        sx={{
                                            height: 0,
                                            width: 0,
                                            padding: `${pxToVh(16)}vh ${pxToVh(20)}vh`,
                                            borderRadius: 0,
                                        }}
                                        icon={
                                            <img
                                                src={makeQuestionDisable ? DisabledCheckboxSvg : CheckboxSvg}
                                                alt=""
                                                className="img"
                                            />
                                        }
                                        checkedIcon={<CheckBoxIcon />}
                                    />
                                }
                                label={
                                    <Typography
                                        className={`${stylesComp.formControlLabel}`}
                                        style={{ fontWeight: 500 }}
                                    >
                                        {SETUP_SURVEY_TEXT.QUESTIONS_HEADING}
                                    </Typography>
                                }
                            />
                            {questions?.map(({ question_text, uuid }, qIndex) => {
                                return (
                                    <FormControlLabel
                                        disabled={makeQuestionDisable}
                                        sx={{ margin: 0 }}
                                        key={`${qIndex}-${uuid}`}
                                        control={
                                            <Checkbox
                                                disableRipple
                                                checked={!allQuesNotSelected}
                                                icon={
                                                    <img
                                                        src={makeQuestionDisable ? DisabledCheckboxSvg : CheckboxSvg}
                                                        alt=""
                                                        className="img"
                                                    />
                                                }
                                                checkedIcon={<CheckBoxIcon />}
                                                sx={{
                                                    height: 0,
                                                    width: 0,
                                                    padding: `${pxToVh(16)}vh ${pxToVh(20)}vh`,
                                                    borderRadius: 0,
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                className={`${stylesComp.formControlLabel}`}
                                                dangerouslySetInnerHTML={{ __html: question_text }}
                                            />
                                        }
                                    />
                                );
                            })}
                        </FormGroup>
                    </QuestionContent>
                </AccordionDetailsStyled>
            </AccordionStyled>
        </Modulebody>
    );
};

export default TeiQuestions;

export const TabsWrapper = styled.div`
    padding: 20px 0 0 0;
    align-self: stretch;
`;
