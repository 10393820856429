import { Checkbox, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import CheckboxSvg from '../../assets/img/Checkbox.svg';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { colorsCode, pxToVh, pxToVw } from '../../common/colors';
import { CommonChip, CommonLabel, Img } from '../../common/FormInputs/Common';
import CloseIcon from '../../assets/img/close.svg';
import { CTA, FlexColumnGap, FlexStartGap } from '../../common/styled/styled';
import DropdownMenu from '../../common/FormInputs/DropdownMenu';
import { cloneDeep } from 'lodash';
import { DownloadMonitorFiles } from '../../store/actions/Surveys/action';
import React from 'react';
import { Langdiv } from '../Dashboard/partials/Admin/ManageSurveys';

const checkBoxStyle = {
    width: `${pxToVw(18)}vw`,
    height: `${pxToVh(18)}vh`,
};

const styleObj = {
    width: `${pxToVw(23)}vw`,
    height: `${pxToVh(23)}vh`,
    color: colorsCode.TextHeading,
};

const SurveyExportModal = (
    surveyExport,
    setSurveyExport,
    surveyLanguages,
    setSurveyLanguages,
    setId,
    dispatchAction,
    params,
    surveys
) => {
    const getCheckBox = expType => {
        return (
            <Checkbox
                sx={{
                    height: 0,
                    width: 0,
                    opacity: expType === 'base' ? 0.4 : 1,
                }}
                icon={<img src={CheckboxSvg} alt="" className="img" style={checkBoxStyle} />}
                onClick={() => {
                    if (expType !== 'base') {
                        setSurveyExport({
                            ...surveyExport,
                            type: expType,
                            isMulti: !surveyExport.isMulti,
                        });
                        setSurveyLanguages(
                            surveyLanguages?.map(lang => {
                                return { ...lang, is_selected: false };
                            })
                        );
                    }
                }}
                checked={expType === 'base' ? true : surveyExport.type === expType && surveyExport?.isMulti}
                checkedIcon={<CheckBoxIcon sx={styleObj} />}
            />
        );
    };
    const closeModal = () => {
        setSurveyExport({ open: false, type: 'base' });
        setId({
            uuid: '',
            survey_template: '',
            status: '',
            langid: '',
        });
    };
    return (
        <Dialog
            open={surveyExport.open}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(580)}vw`,
                    width: `${pxToVw(580)}vw`,
                    borderRadius: '8px',
                },
            }}
        >
            <DialogContent
                sx={{
                    p: `${pxToVh(30)}vh ${pxToVh(30)}vw 0vh ${pxToVh(30)}vw`,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Img image={CloseIcon} h={pxToVh(24)} w={pxToVw(24)} alt="close" cursor="pointer" />
                </IconButton>
                <FlexColumnGap gap={pxToVh(40)}>
                    <CommonLabel
                        textWeight={500}
                        textSize={24}
                        textColor={colorsCode.TextHeading}
                        lineHeight={36}
                        label="Survey Export"
                    />
                    <FlexColumnGap gap={pxToVh(40)}>
                        <CommonLabel
                            textWeight={400}
                            textSize={16}
                            textColor={colorsCode.TextHeading}
                            lineHeight={24}
                            label="The export will be in the Excel (xls) format"
                        />
                        <FlexColumnGap gap={pxToVh(8)}>
                            <CommonLabel
                                textWeight={400}
                                textSize={16}
                                textColor={colorsCode.Neutral30}
                                lineHeight={24}
                                label="Survey ID"
                            />
                            <FlexColumnGap gap={pxToVh(24)}>
                                <CommonLabel
                                    textWeight={400}
                                    textSize={16}
                                    textColor={colorsCode.Neutral80}
                                    lineHeight={24}
                                    customStyles={{
                                        textTransform: 'uppercase',
                                    }}
                                    label={surveys.find(({ uuid }) => uuid === params.uuid)?.survey_id}
                                />
                                <FlexStartGap gap={pxToVw(8)} style={{ alignItems: 'center' }}>
                                    {getCheckBox('base')}
                                    <CommonLabel
                                        textWeight={surveyExport.type === 'base' ? 400 : 300}
                                        textSize={18}
                                        textColor={colorsCode.Neutral80}
                                        lineHeight={28}
                                        label="Base language"
                                    />
                                    <Langdiv>
                                        <CommonLabel
                                            textWeight={surveyExport.type === 'base' ? 400 : 300}
                                            textSize={14}
                                            textColor={colorsCode.Neutral80}
                                            lineHeight={16}
                                            label="English (US)"
                                        />
                                    </Langdiv>
                                </FlexStartGap>
                                <FlexColumnGap gap={pxToVh(16)} style={{ alignSelf: 'stretch' }}>
                                    <FlexStartGap gap={pxToVw(8)} style={{ alignItems: 'center' }}>
                                        {getCheckBox('multi')}
                                        <CommonLabel
                                            textWeight={surveyExport.type === 'multi' ? 400 : 300}
                                            textSize={18}
                                            textColor={colorsCode.Neutral80}
                                            lineHeight={28}
                                            label="Select another language"
                                        />
                                    </FlexStartGap>
                                    {surveyExport.isMulti ? (
                                        <>
                                            <DropdownMenu
                                                id="lang"
                                                name="lang"
                                                menuItems={surveyLanguages}
                                                placeholder="Select a language"
                                                width={pxToVw(480)}
                                                onClickMultiple={(e, i) => {
                                                    const {
                                                        target: { checked },
                                                    } = e;
                                                    const copy = cloneDeep(surveyLanguages);
                                                    copy[i].is_selected = checked;
                                                    setSurveyLanguages(copy);
                                                }}
                                                multiple={true}
                                                value={surveyLanguages}
                                                isMultiple={true}
                                            />
                                            <FlexStartGap
                                                style={{
                                                    flexWrap: 'wrap',
                                                    gap: `${pxToVh(7)}vh ${pxToVw(1)}vw`,
                                                }}
                                            >
                                                {surveyLanguages?.map(({ is_selected, name }, i) => {
                                                    if (is_selected === true) {
                                                        return (
                                                            <CommonChip
                                                                key={i}
                                                                value={name}
                                                                onDeleteHandler={() => {
                                                                    const copy = cloneDeep(surveyLanguages);
                                                                    copy[i].is_selected = false;
                                                                    setSurveyLanguages(copy);
                                                                }}
                                                                canDelete={true}
                                                            />
                                                        );
                                                    }
                                                })}
                                            </FlexStartGap>
                                        </>
                                    ) : null}
                                </FlexColumnGap>
                            </FlexColumnGap>
                        </FlexColumnGap>
                    </FlexColumnGap>
                </FlexColumnGap>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', mb: `${pxToVh(35)}vh`, mt: `${pxToVh(40)}vh` }}>
                <FlexStartGap gap={pxToVw(24)}>
                    <CTA
                        size={16}
                        fw={400}
                        lh={24}
                        color={colorsCode.PrimaryDeepBlue}
                        bg={colorsCode.white}
                        bcolor={colorsCode.PrimaryDeepBlue}
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </CTA>
                    <CTA
                        size={16}
                        fw={400}
                        lh={24}
                        color={colorsCode.white}
                        bg={colorsCode.PrimaryDeepBlue}
                        onClick={() => {
                            dispatchAction(
                                DownloadMonitorFiles({
                                    surveyId: params.uuid,
                                    langid: [
                                        surveys?.find(({ uuid }) => uuid === params.uuid)?.language_id,
                                        ...new Set(
                                            surveyLanguages.filter(lang => lang?.is_selected)?.map(lang => lang?.uuid)
                                        ),
                                    ],
                                })
                            );
                            setSurveyExport({
                                open: false,
                                type: 'base',
                            });
                        }}
                    >
                        Export
                    </CTA>
                </FlexStartGap>
            </DialogActions>
        </Dialog>
    );
};

export default SurveyExportModal;
