import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import { CTA, FlexColumnGap, FlexRow } from '../../../../common/styled/styled';
import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Icon,
    ListSubheader,
    MenuItem,
} from '@mui/material';
import { DialogActionsStyled } from '../../../Surveys/partials/setup-survey/Finalize';
import CloseIcon from '../../../../assets/img/close.svg';
import Note from '../../../../assets/img/note-grey.svg';
import ArrowDownIcon from '../../../../assets/img/arrow_down.svg';
import { CommonChip, Img } from '../../../../common/FormInputs/Common';
import { SelectStyled } from '../../../../common/FormInputs/DropdownMenu';
import Text from '../../../KnowledgeCenter/Text';
import { api } from '../../../../http/api';
import { Endpoints } from '../../../../http/Endpoints';

const ResponseReporting = ({ surveyId, onClose, clientName, totalCompleted, setShowToast }) => {
    const [selectedOptions, setSelectedOptions] = useState(['Overall']);
    const [groupedOptions, setGroupedOptions] = useState({});
    const isMaxSelectionReached = selectedOptions.length >= 3;

    const handleChange = option => {
        const newSelected = selectedOptions.includes(option)
            ? selectedOptions.filter(item => item !== option)
            : [...selectedOptions, option];

        if (newSelected.length > 3) return;

        setSelectedOptions(newSelected);
    };
    const getDropdownData = async () => {
        try {
            const res = await api.get(`${Endpoints.getReportBenchmark.replace(':survey_id', surveyId)}`);
            setGroupedOptions(res.data.data);
        } catch (error) {
            console.log('error', error);
        }
    };
    useEffect(() => {
        getDropdownData();
    }, []);

    const handleDownloadPPT = async () => {
        onClose();
        try {
            const res = await api.post(`${Endpoints.exportPPTReport.replace(':survey_id', surveyId)}`, {
                benchmarks: !selectedOptions?.length ? ['Overall'] : selectedOptions,
            });
            setShowToast(res.data.message);
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <Dialog
            open={true}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(520)}vw`,
                    width: `${pxToVw(520)}vw`,
                },
            }}
        >
            <FlexRow justifyContent="flex-end" margin="1vw 1vw 0 0">
                <Icon
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: `${pxToVw(16)}vw`,
                        top: `${pxToVh(12)}vh`,
                        cursor: 'pointer',
                    }}
                >
                    <img
                        src={CloseIcon}
                        alt=""
                        style={{
                            height: `${pxToVh(18)}vh`,
                            width: ` ${pxToVw(18)}vw`,
                            cursor: 'pointer',
                        }}
                        onClick={onClose}
                    />
                </Icon>
            </FlexRow>
            <DialogTitle sx={{ padding: `20px 30px 30px` }}>
                <Text fontColor={colorsCode.TextHeading} fontSize="24px" label="Response report" fontWeight={500} />
            </DialogTitle>
            <DialogContent sx={{ padding: `10px 30px` }}>
                <FlexColumnGap gap="0" style={{ marginBottom: '40px' }}>
                    <FlexRow>
                        <Img image={Note} />
                        <Text fontColor={colorsCode.TextSubtle} fontSize="14px" label={clientName} fontWeight={400} />
                    </FlexRow>
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize="16px"
                        label={`N=${totalCompleted}`}
                        fontWeight={400}
                    />
                </FlexColumnGap>

                <Text
                    fontColor={colorsCode.Neutral80}
                    fontSize="16px"
                    label="Benchmark (Select upto 3 options)"
                    fontWeight={400}
                />
                <FormControl fullWidth>
                    <SelectStyled
                        multiple
                        value={Object.entries(groupedOptions)}
                        renderValue={() => <span className="dropdown-placeholder">Select benchmark</span>}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                },
                            },
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        sx={{ height: '45px', marginTop: '10px' }}
                        IconComponent={props => <img src={ArrowDownIcon} {...props} alt="dropdown" />}
                    >
                        <MenuItemStyled value="Overall">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedOptions.includes('Overall')}
                                        disabled={isMaxSelectionReached && !selectedOptions.includes('Overall')}
                                        onChange={() => handleChange('Overall')}
                                        sx={{
                                            '&.Mui-disabled': {
                                                background: colorsCode.Neutral80,
                                                opacity: 0.5,
                                                height: '20px',
                                                width: '20px',
                                                borderRadius: '2px',
                                                padding: '0',
                                                margin: '10px 12px',
                                            },
                                        }}
                                    />
                                }
                                label="Overall"
                                className="Overall"
                            />
                        </MenuItemStyled>

                        {Object.entries(groupedOptions)
                            .filter(([group]) => group !== 'Overall')
                            ?.map(([group, items]) => (
                                <React.Fragment key={group}>
                                    <ListSubheaderStyled>{group}</ListSubheaderStyled>
                                    {items?.map(item => (
                                        <MenuItemStyled key={item} value={item}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedOptions.includes(item)}
                                                        disabled={
                                                            isMaxSelectionReached && !selectedOptions.includes(item)
                                                        }
                                                        sx={{
                                                            '&.Mui-disabled': {
                                                                background: colorsCode.Neutral80,
                                                                opacity: 0.5,
                                                                height: '20px',
                                                                width: '20px',
                                                                borderRadius: '2px',
                                                                padding: '0 !important',
                                                                margin: '0 12px',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={item}
                                                className="OtherOptions"
                                                onChange={() => handleChange(item)}
                                            />
                                        </MenuItemStyled>
                                    ))}
                                </React.Fragment>
                            ))}
                    </SelectStyled>
                </FormControl>
                <div style={{ paddingTop: '10px' }}>
                    {selectedOptions.map((item, i) => {
                        return (
                            <CommonChip
                                key={i}
                                value={item}
                                onDeleteHandler={() => {
                                    handleChange(item);
                                }}
                                canDelete={true}
                            />
                        );
                    })}
                </div>
            </DialogContent>

            <DialogActionsStyled sx={{ justifyContent: 'center', padding: `${pxToVh(30)}vh 0` }}>
                <CTA
                    size={16}
                    lh={24}
                    fw={400}
                    bg={colorsCode.white}
                    pt={8}
                    pl={16}
                    color={colorsCode.PrimaryDeepBlue}
                    bcolor={colorsCode.PrimaryDeepBlue}
                    onClick={onClose}
                >
                    Cancel
                </CTA>
                <CTA
                    size={16}
                    lh={24}
                    fw={400}
                    bg={colorsCode.PrimaryDeepBlue}
                    pt={8}
                    pl={16}
                    color={colorsCode.white}
                    onClick={() => {
                        handleDownloadPPT();
                    }}
                >
                    Download ppt
                </CTA>
            </DialogActionsStyled>
        </Dialog>
    );
};

export default ResponseReporting;

const ListSubheaderStyled = styled(ListSubheader)`
    && {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: ${colorsCode.TextSubtle};
        padding: ${pxToVh(4)}vh 16px;
    }
`;

const MenuItemStyled = styled(MenuItem)`
    .Overall {
        padding: 0 2px;
    }
    .OtherOptions {
        .MuiButtonBase-root {
            padding: 0 10px;
        }
    }
`;
