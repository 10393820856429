import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Paper } from '@mui/material';
import styled from 'styled-components';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import { CommonLabel } from '../../../../common/FormInputs/Common';
import {
    Flex,
    FlexColumn,
    FlexColumnBetween,
    FlexColumnGap,
    FlexRow,
    FlexStartGap,
} from '../../../../common/styled/styled';
import InputField from '../../../../common/FormInputs/InputField';
import PageHeader from './common-components/PageHeader';
import {
    Default_Lang,
    LABELS,
    MA_TEMPLATE,
    MESSAGE,
    NPM_TEMPLATE,
    REGEX,
    REPLACE_CHARS,
    TEI_TEMPLATE,
} from '../../../../global-constants';
import { Endpoints } from '../../../../http/Endpoints';
import Loader from '../../../Loader/Loader';
import { api } from '../../../../http/api';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, findIndex } from 'lodash';
import DropdownMenu from '../../../../common/FormInputs/DropdownMenu';
import Header from '../create/Header';
import { ShowMessage } from '../../../../common/FormInputs/Snackbar';
import { UserTemplates } from '../../../../store/actions/Surveys/action';
import LangSidebar from './common-components/LangSidebar';
import { updateWording } from '../../../../store/reducers/slice/SurveySlice';

let errorMsg = '';
const Wording = ({
    languages = [],
    defaultLang = '',
    goToNext = () => {},
    setErrorState,
    cd = {},
    errorState,
    setShowMessage,
    styles,
    unsaved,
    setUnsaved,
    setShowErrWarning,
    showErrWarning,
}) => {
    const [currentLang, setCurrentLang] = useState();
    const { surveyInformation: si = {} } = useSelector(state => state.surveyReducer);
    const { data: templateData = [] } = useSelector(state => state.userTemplates);
    const { client_name: cn = '' } = cd || {};
    const [apiState, setState] = useState({
        loading: false,
        message: '',
        error: false,
        open: false,
    });
    const [data, setData] = useState([]);
    const [errorId, setErrorId] = useState({});
    const [isFirstRender, setIsFirstRender] = useState(false);
    const ref = useRef();
    const dispatch = useDispatch();
    const clientFieldPresent = data.length ? data.filter(({ wording_keys }) => wording_keys === 'client_name') : [];
    const {
        replace_word: firstReplaceWord = '',
        translation: firstTranslation = {},
        wording_question: firstWordQues = '',
        wording_hint_text: firstWordHint = '',
        wording_keys: wk = '',
    } = clientFieldPresent[0] || {};
    const emailFieldPresent =
        data.length && data.filter(({ wording_keys }) => wording_keys === 'help_email').length ? 1 : 0;
    const otherFieldPresent =
        data.length && data.filter(({ wording_keys }) => !['client_name', 'help_email'].includes(wording_keys)).length
            ? 1
            : 0;
    const isLteTemplate = si?.schema_name === 'template_ohi_lte';
    const isNpmTemplate = si?.schema_name === NPM_TEMPLATE;
    const isMATemplate = si?.schema_name === MA_TEMPLATE;
    const isTeiTemplate = si?.schema_name === TEI_TEMPLATE;

    useEffect(() => {
        setCurrentLang(defaultLang);
    }, [defaultLang]);

    useEffect(() => {
        if (!templateData.length) {
            dispatch(UserTemplates());
        }
        //get wording data
        getWordingData();
        dispatch(updateWording(true));
        return async () => {
            try {
                if (ref?.current?.length) {
                    if (!flagOfUnsaved(ref?.current) || isNpmTemplate) {
                        setUnsaved(false);
                        await api.put(
                            Endpoints.getWording.replace(':survey_id', si.uuid),
                            ref.current.map(({ replace_text, translation, uuid }) => ({
                                replace_text,
                                translation,
                                uuid,
                            }))
                        );
                        await dispatch(updateWording(false));
                    }
                }
            } catch (error) {
                console.warn('::Exception:::');
            }
        };
    }, []);

    const sanitize = str => {
        const reg = /[=*<>]/gi;
        return str.replace(reg, match => {
            return REPLACE_CHARS[match];
        });
    };

    const getWordingData = async () => {
        try {
            const copy = { ...errorId };
            setState({ ...apiState, loading: true });
            const response = await api.get(Endpoints.getWording.replace(':survey_id', si.uuid));
            setState({ ...apiState, loading: false });
            let responseArray = response.data.data || [];
            responseArray.map(item => {
                const { wording_keys, replace_text, translation = {}, replace_word_choices } = item;
                // if (item.is_selected) {
                copy[wording_keys] = '';
                //set client name default for all languages
                if (wording_keys === 'client_name') {
                    const removeAmp = cn?.replaceAll('amp;', '');
                    item.replace_text = replace_text ? replace_text?.replaceAll('amp;', '') : removeAmp;
                    languages.forEach(({ short_code }) => {
                        item.translation[short_code] = !item.translation[short_code]
                            ? removeAmp
                            : item?.translation[short_code]?.replaceAll('amp;', '');
                    });
                }
                if (replace_word_choices && !['client_name', 'help_email'].includes(wording_keys)) {
                    if (
                        translation[Default_Lang] === 'Custom' ||
                        (!replace_word_choices.includes(translation[Default_Lang]) &&
                            translation[Default_Lang] !== '^client_name^')
                    ) {
                        item['custom'] = translation[Default_Lang]?.replaceAll('amp;', '');
                        item.translation[Default_Lang] = 'Custom';
                    }
                }
                if (replace_word_choices && replace_word_choices.length) {
                    //replace_word_choices formation
                    item.og_replace_word_choices = replace_word_choices;
                    const array = replace_word_choices
                        .split('|')
                        .filter(item => item.length > 0)
                        .map(item => ({ name: item, uuid: item }));
                    if (wording_keys === 'company_name') {
                        // push org name option at 2nd last position
                        const orgName = responseArray[0].translation[defaultLang];
                        const arrayLastVal = array[array.length - 1];
                        array[array.length - 1] = {
                            name: 'Organisation Name specified above',
                            uuid: orgName,
                            org_name: true,
                        };
                        array.push(arrayLastVal);
                        // end
                        if (item.replace_text === '^client_name^') {
                            item.replace_text === orgName;
                            item.translation[Default_Lang] = orgName;
                            item.useOrgName = true;
                        } else {
                            item.useOrgName = false;
                        }
                    }
                    item.replace_word_choices = array;
                }
                // }
            });
            setErrorId(copy);
            setData(responseArray);
            flagOfUnsaved(responseArray); //check if data is empty
            ref.current = requestParams(responseArray);
            const emailFieldPosition = responseArray.findIndex(field => field.wording_keys === 'help_email');
            if (emailFieldPosition > -1 && responseArray[emailFieldPosition]?.replace_text === '') {
                setIsFirstRender(true);
            }
        } catch (error) {
            const message = error?.response?.data?.message || MESSAGE.Error_Add_Client;
            setState({
                loading: false,
                message,
                error: true,
                open: true,
            });
            setShowMessage({
                message,
                open: true,
                type: 'error',
            });
        }
    };

    const handleLanguage = short_code => {
        if (currentLang !== short_code && unsaved) {
            setErrorState(true);
            setShowErrWarning(true);
            return;
        }
        if (!errorState && !unsaved) {
            setErrorState(false);
            setShowErrWarning(false);
            setCurrentLang(short_code);
        }
    };

    const handleChangeFn = (e, i) => {
        const {
            target: { value },
        } = e;
        const copy = cloneDeep(data);
        const purified = sanitize(value);
        copy[i].replace_text = purified;
        copy[i].translation['en'] = purified;
        setErrorMessage(copy, purified, i, copy[i].wording_keys);
        if (value === 'Custom') {
            copy[i]['custom'] = '';
        }
        copy[i].translation = {
            ...data[i].translation,
            [currentLang]: purified,
        };
        if (!isNpmTemplate && !isLteTemplate && !isMATemplate && !isTeiTemplate) {
            if (i === 0 && currentLang === defaultLang) {
                // updating dropdown options when org name is changed
                const replaceWordChoices = cloneDeep(copy[1].replace_word_choices);
                const orgNamePosition = findIndex(replaceWordChoices, ['org_name', true]);
                replaceWordChoices[orgNamePosition] = {
                    name: 'Organisation Name specified above',
                    uuid: value,
                    org_name: true,
                }; // main step
                copy[1].replace_word_choices = replaceWordChoices;
            }

            //if org dropdown is selected and user is changing org name
            if (i === 0 && copy[1].useOrgName) {
                copy[1].replace_text = purified;
                copy[1].translation['en'] = purified;
                copy[1].translation = {
                    ...data[i].translation,
                    [currentLang]: purified,
                };
            }

            let isOrgName = true;
            if (value && i === 1 && copy[i].og_replace_word_choices.includes(value)) {
                isOrgName = false;
            }

            if (i === 1) {
                copy[i].useOrgName = isOrgName;
            }
        }
        setData(copy);
        flagOfUnsaved(copy);
        ref.current = requestParams(copy);
    };

    /**
     * set payload for submit data
     * @param {*} array
     * @returns
     */
    const requestParams = array => {
        let responseData = [];
        array.forEach(
            ({
                uuid,
                translation,
                replace_text,
                custom = '',
                replace_word_choices,
                useOrgName,
                wording_keys: wk = '',
            }) => {
                const flag = ['client_name', 'help_email'].includes(wk);
                if (flag) {
                    responseData.push({
                        replace_text: replace_text?.replaceAll('amp;', ''),
                        uuid,
                        translation,
                    });
                } else {
                    const text = useOrgName ? '^client_name^' : '';
                    const customFlag = translation[Default_Lang] === 'Custom' && replace_word_choices;
                    const params = {
                        replace_text: text ? text : replace_text?.replaceAll('amp;', ''),
                        wording_keys: wk,
                        uuid,
                    };
                    if (useOrgName) {
                        params.translation = { [Default_Lang]: text ? text : translation[Default_Lang] };
                    } else if (customFlag) {
                        (params.custom = customFlag ? custom?.replaceAll('amp;', '') : translation[Default_Lang]),
                            (params.translation = { [Default_Lang]: custom?.replaceAll('amp;', '') });
                    } else {
                        params.translation = { [Default_Lang]: text ? text : translation[Default_Lang] };
                    }
                    //languages object only default lang
                    responseData.push(params);
                }
            }
        );
        return responseData;
    };

    /**
     * show error messages on the basis of validation type
     * if empty, show `required` message
     * if invalid input, show `invalid type` message
     * @param {*} copy
     * @param {*} purified
     * @param {*} i
     */
    const setErrorMessage = (copy, purified, i, wording_keys) => {
        let msg = '';
        switch (wording_keys) {
            case 'client_name':
                if (!purified) {
                    msg = `Enter your ${copy[i].replace_word}'s name.`;
                } else if (purified && !REGEX.clientName.test(purified)) {
                    msg = `invalid`;
                }
                break;
            case 'help_email':
                if (!purified) {
                    msg = 'Please enter an email';
                } else if (purified && !REGEX.isValidEmail.test(purified)) {
                    msg = 'Please enter a valid email.';
                }
                break;
            default:
                if (!purified) {
                    msg = `Please enter ${copy[i].replace_word}`;
                } else if (purified && !REGEX.allowedCharacter.test(purified)) {
                    msg = `Please enter a valid ${copy[i].replace_word}.`;
                }
        }
        setErrorId({ ...errorId, [wording_keys]: msg });
    };

    /**
     * validate and submit wording data
     */
    const submitWording = async () => {
        try {
            if (validation() > 0) {
                setErrorState(true);
                setShowMessage({
                    open: true,
                    message: errorMsg,
                    type: 'error',
                });
            } else {
                setErrorState(false);
                dispatch(updateWording(false));
                setState({ ...apiState, loading: true });
                await api.put(
                    Endpoints.getWording.replace(':survey_id', si.uuid),
                    requestParams(data).map(({ replace_text, translation, uuid }) => ({
                        replace_text,
                        translation,
                        uuid,
                    }))
                );
                ref.current = [];
                setShowMessage({
                    open: true,
                    message: 'Wording data saved successfully',
                    type: 'success',
                });
                goToNext();
                setState({ ...apiState, loading: false, open: true, message: 'Data saved successfully' });
            }
        } catch (error) {
            const message = error?.response?.data?.message || MESSAGE.Error_Add_Client;
            setShowMessage({
                open: true,
                message,
                type: 'error',
            });
            setState({ loading: false, open: true, message, error: true });
        }
    };

    /**
     * validtion method to set errors and calculate errors count
     * @param {*} arr
     * @returns
     */
    const validation = (arr = []) => {
        const array = arr.length ? arr : data;
        const copyError = { ...errorId };
        array.forEach(({ translation, custom = '', replace_word = '', wording_keys = '' }, i) => {
            if (wording_keys === 'client_name') {
                languageValidation(translation, i, wording_keys, copyError);
            } else if (wording_keys === 'help_email') {
                languageValidation(translation, i, wording_keys, copyError);
            } else {
                if (translation[Default_Lang] === 'Custom' && !custom) {
                    errorMsg = `Please fill all required fields`;
                    copyError[wording_keys] = `Please enter ${replace_word}`;
                }
            }
        });
        setErrorId(copyError);
        const errorCount = Object.entries(copyError).filter(([key, value]) => key.length && value.length > 0).length;
        return errorCount;
    };

    /**
     * language validation for fields, if empty in any language
     * show mesage and set errorid
     * @param {*} translation
     * @param {*} id
     * @param {*} copyError
     */
    const languageValidation = (translation, id, wording_keys, copyError) => {
        languages.forEach(({ short_code }) => {
            const clientEmailErr =
                wording_keys === 'client_name'
                    ? `Please enter your ${data[id].replace_word}'s name.`
                    : `Please enter an email`;
            if (!!translation[short_code] === false) {
                errorMsg = clientEmailErr;
                copyError[wording_keys] = clientEmailErr;
            }
        });
    };

    const handleBlurEvent = (e, id) => {
        const clone = cloneDeep(data);
        languages.forEach(({ short_code }) => {
            clone[id].translation = {
                ...clone[id].translation,
                [short_code]: clone[id].translation[Default_Lang],
            };
        });
        setData(clone);
        ref.current = requestParams(clone); //set data in reference
        flagOfUnsaved(clone);
    };

    const handleCustom = (e, i) => {
        const {
            target: { value },
        } = e;
        const purified = sanitize(value);
        const copy = cloneDeep(data);
        const copyError = { ...errorId };
        const { wording_keys } = copy[i];
        copy[i].replace_text = purified;
        copy[i]['custom'] = purified;
        const msg = !purified
            ? `Please enter the term for "${(copy[i].replace_word || '').toLowerCase()}"`
            : purified && !REGEX.allowedCharacter.test(purified)
            ? `invalid custom`
            : '';
        copyError[wording_keys] = msg;
        setErrorId(copyError);
        flagOfUnsaved(copy);
        ref.current = requestParams(copy);
        setData(copy);
    };

    /**
     * count is if field is empty in any language
     * @param {*} array
     * @returns
     */
    const flagOfUnsaved = array => {
        const flag = array
            .map(({ translation, custom, replace_text, wording_keys }) => {
                if (wording_keys === 'help_email') {
                    if (!replace_text) return false;
                    if (!REGEX.isValidEmail.test(replace_text)) return false;
                } else if (!['client_name', 'help_email'].includes(wording_keys)) {
                    if (!translation[Default_Lang] || !replace_text) return false;
                    if ((translation[Default_Lang] === 'Custom' || replace_text === 'Custom') && !custom) return false;
                    return true;
                } else {
                    return languages.map(({ short_code }) => {
                        if (['help_email', 'client_name'].includes(wording_keys)) {
                            return !!translation[short_code];
                        }
                    });
                }
            })
            .flat()
            .filter(item => item === false).length;
        setUnsaved(flag);
        if (!flag) {
            setErrorState(false);
        }
        return flag ? true : false;
    };

    const showEnglishUsText = langText => {
        if (currentLang !== defaultLang) {
            return <EnglishText>{`English (US): ${langText}`}</EnglishText>;
        }
        return null;
    };

    const getText = text => (isLteTemplate || isTeiTemplate ? text : text.toLowerCase());

    return (
        <Fragment>
            <Header
                position="fixed"
                zIndex={999}
                width="100%"
                clickHandler={submitWording}
                isNextEnabled={!errorState && !unsaved}
                loading={apiState.loading}
            />
            <EditSurveyDiv>
                {apiState.loading && <Loader showBackground position="fixed" background="transparent" />}
                <Paper elevation={2} sx={styles}>
                    <PageHeader
                        title="Customize survey wording"
                        desc={
                            isNpmTemplate ? LABELS.SURVEY_MODULE.WORDING.TITLENPM : LABELS.SURVEY_MODULE.WORDING.TITLE
                        }
                    />
                    <Flex>
                        <LangSidebar
                            title={`Please visit each language option to review${
                                !isNpmTemplate ? '/customize' : ''
                            } the survey wording`}
                            languages={languages}
                            currentLang={currentLang}
                            errorState={errorState}
                            onLanguageSelect={language => handleLanguage(language)}
                        />
                        {/* wording section */}
                        <FlexColumn>
                            <WordingDiv>
                                <FlexColumnGap gap={pxToVh(20)}>
                                    <CommonLabel
                                        textSize={18}
                                        lineHeight={28}
                                        textWeight={500}
                                        textColor={colorsCode.TextHeading}
                                        label="Organization name"
                                    />
                                    {data.length && clientFieldPresent.length ? (
                                        <FlexStartGap gap={pxToVw(20)} style={{ alignSelf: 'stretch' }} key={0}>
                                            <FlexColumnGap gap={pxToVh(8)}>
                                                <CommonLabel
                                                    textSize={16}
                                                    lineHeight={24}
                                                    textWeight={400}
                                                    textColor={colorsCode.TextHeading}
                                                    label={firstWordQues}
                                                />
                                                <InputField
                                                    placeholder={`Please enter ${firstReplaceWord}`}
                                                    name={firstReplaceWord}
                                                    id={firstReplaceWord}
                                                    width={pxToVw(500)}
                                                    onChangeFn={e => {
                                                        if (e.target.value.trim() || firstTranslation[currentLang])
                                                            handleChangeFn(e, 0);
                                                    }}
                                                    val={firstTranslation[currentLang]}
                                                    error={errorId[wk]}
                                                    errorMessage={errorId[wk]?.replace(
                                                        firstReplaceWord,
                                                        'organization'
                                                    )}
                                                />
                                                {showEnglishUsText(firstTranslation[Default_Lang])}
                                            </FlexColumnGap>
                                            {isNpmTemplate || isMATemplate ? null : (
                                                <InfoDiv>
                                                    <ul>
                                                        {firstWordHint
                                                            ? firstWordHint.split('|').map((whi, i) => (
                                                                  <li key={i}>
                                                                      <CommonLabel
                                                                          label={whi
                                                                              .replaceAll(
                                                                                  'Organisation Name',
                                                                                  `<span class="boldText">${firstTranslation[currentLang]}</span>`
                                                                              )
                                                                              .replaceAll(
                                                                                  '^client_name^',
                                                                                  `<span class="boldText">${firstTranslation[currentLang]}</span>`
                                                                              )}
                                                                          textSize={14}
                                                                          textColor={colorsCode.Neutral80}
                                                                          textWeight={300}
                                                                          lineHeight={20}
                                                                      />
                                                                  </li>
                                                              ))
                                                            : null}
                                                    </ul>
                                                </InfoDiv>
                                            )}
                                        </FlexStartGap>
                                    ) : null}
                                </FlexColumnGap>
                            </WordingDiv>
                            {/* replace words */}
                            {otherFieldPresent && currentLang === Default_Lang ? (
                                <WordingDiv>
                                    <FlexColumnBetween style={{ gap: `${pxToVh(48)}vh` }}>
                                        <CommonLabel
                                            textSize={18}
                                            lineHeight={28}
                                            textWeight={500}
                                            textColor={colorsCode.TextHeading}
                                            label="Replace words as they appear on survey"
                                        />
                                        {data.length
                                            ? data.map((item, i) => {
                                                  const {
                                                      wording_question = '',
                                                      replace_word_choices = '',
                                                      translation = {},
                                                      replace_word = '',
                                                      wording_hint_text = '',
                                                      useOrgName: excludeThe = '',
                                                      wording_keys: wk = '',
                                                  } = item || {};
                                                  if (!['client_name', 'help_email'].includes(wk)) {
                                                      const currentLangText = translation[currentLang] || '';
                                                      const defaultLangText = translation[Default_Lang];
                                                      const ifCustomText = defaultLangText === 'Custom';
                                                      const messageError = !currentLangText
                                                          ? `Please provide ${replace_word.toLowerCase()}`
                                                          : '';
                                                      const errorFlag =
                                                          !currentLangText || (errorState && !currentLangText);
                                                      const text = ifCustomText
                                                          ? item['custom'] || defaultLangText
                                                          : currentLangText;
                                                      const replaceWord = !excludeThe
                                                          ? replace_word
                                                          : `The ${replace_word.toLowerCase()}`;

                                                      return (
                                                          <FlexStartGap
                                                              gap={pxToVw(20)}
                                                              style={{ alignSelf: 'stretch' }}
                                                              key={i}
                                                          >
                                                              <FlexColumnGap
                                                                  gap={pxToVh(8)}
                                                                  style={{ width: `${pxToVw(540)}vw` }}
                                                              >
                                                                  <CommonLabel
                                                                      textSize={16}
                                                                      lineHeight={24}
                                                                      textWeight={400}
                                                                      textColor={colorsCode.TextHeading}
                                                                      label={wording_question}
                                                                  />
                                                                  {replace_word_choices &&
                                                                  replace_word_choices.length ? (
                                                                      <DropdownMenu
                                                                          menuItems={replace_word_choices}
                                                                          value={currentLangText}
                                                                          onChangeFn={e => handleChangeFn(e, i)}
                                                                          name={replace_word}
                                                                          id={replace_word}
                                                                          width={pxToVw(500)}
                                                                          error={errorFlag}
                                                                          message={messageError}
                                                                      />
                                                                  ) : (
                                                                      <InputField
                                                                          val={currentLangText}
                                                                          placeholder={`Please enter ${replace_word}`}
                                                                          name={replace_word}
                                                                          id={replace_word}
                                                                          onChangeFn={e => handleChangeFn(e, i)}
                                                                          width={pxToVw(540)}
                                                                          error={errorFlag}
                                                                          message={messageError.replace(
                                                                              'provide',
                                                                              'enter'
                                                                          )}
                                                                      />
                                                                  )}
                                                                  {ifCustomText ? (
                                                                      <InputField
                                                                          name={replace_word}
                                                                          id={replace_word}
                                                                          placeholder={`Custom`}
                                                                          onChangeFn={e => handleCustom(e, i)}
                                                                          width={pxToVw(500)}
                                                                          val={item['custom']}
                                                                          error={
                                                                              errorId[wk] ||
                                                                              (errorState && !item['custom'])
                                                                          }
                                                                          errorMessage={errorId[wk]}
                                                                      />
                                                                  ) : null}
                                                              </FlexColumnGap>
                                                              {wording_hint_text ? (
                                                                  <InfoDiv>
                                                                      <ul>
                                                                          {wording_hint_text
                                                                              .split('|')
                                                                              .map((whi, i) => {
                                                                                  const value =
                                                                                      !whi.startsWith(replaceWord) &&
                                                                                      !excludeThe
                                                                                          ? getText(text)
                                                                                          : text;
                                                                                  const replaceText = whi.includes(
                                                                                      replaceWord.toLowerCase()
                                                                                  )
                                                                                      ? replaceWord.toLowerCase()
                                                                                      : replaceWord;

                                                                                  return (
                                                                                      <li key={i}>
                                                                                          <CommonLabel
                                                                                              label={whi.replaceAll(
                                                                                                  replaceText,
                                                                                                  `<span class="boldText"><i>${value}</i></span>`
                                                                                              )}
                                                                                              textSize={14}
                                                                                              textColor={
                                                                                                  colorsCode.Neutral80
                                                                                              }
                                                                                              textWeight={300}
                                                                                              lineHeight={20}
                                                                                          />
                                                                                      </li>
                                                                                  );
                                                                              })}
                                                                      </ul>
                                                                  </InfoDiv>
                                                              ) : null}
                                                          </FlexStartGap>
                                                      );
                                                  }
                                              })
                                            : null}
                                    </FlexColumnBetween>
                                </WordingDiv>
                            ) : null}
                            {/* help email */}
                            {emailFieldPresent ? (
                                <WordingDiv>
                                    <FlexColumnGap gap={pxToVh(20)}>
                                        <CommonLabel
                                            textSize={18}
                                            lineHeight={28}
                                            textWeight={500}
                                            textColor={colorsCode.TextHeading}
                                            label="Help email"
                                        />
                                        {data.length
                                            ? data.map((emailItem, i) => {
                                                  const {
                                                      wording_question: emailWordingQues = '',
                                                      translation: emailTranslation = {},
                                                      replace_word: emailReplaceWord = '',
                                                      wording_keys: emailKeys = '',
                                                  } = emailItem;
                                                  const errorFlag =
                                                      errorId[emailKeys] ||
                                                      (errorState && !emailTranslation[currentLang]);
                                                  if (emailKeys === 'help_email') {
                                                      return (
                                                          <FlexStartGap
                                                              gap={pxToVw(8)}
                                                              style={{
                                                                  alignSelf: 'stretch',
                                                                  alignItems: 'baseline',
                                                              }}
                                                              key={i}
                                                          >
                                                              <FlexColumnGap
                                                                  gap={pxToVh(8)}
                                                                  style={{
                                                                      width: `${pxToVw(540)}vw`,
                                                                  }}
                                                              >
                                                                  <FlexRow style={{ alignItems: 'flex-start' }}>
                                                                      <CommonLabel
                                                                          label="@"
                                                                          textColor={colorsCode.Neutral20}
                                                                          textSize={16}
                                                                          textWeight={300}
                                                                          lineHeight={26}
                                                                      />
                                                                      <CommonLabel
                                                                          textSize={16}
                                                                          lineHeight={24}
                                                                          textWeight={400}
                                                                          textColor={colorsCode.TextHeading}
                                                                          label={emailWordingQues}
                                                                      />
                                                                  </FlexRow>
                                                                  <InputField
                                                                      placeholder={`Please enter your email`}
                                                                      name={emailReplaceWord}
                                                                      id={emailReplaceWord}
                                                                      width={pxToVw(500)}
                                                                      onChangeFn={e => {
                                                                          if (
                                                                              e.target.value.trim() ||
                                                                              emailTranslation[currentLang]
                                                                          )
                                                                              handleChangeFn(e, i);
                                                                      }}
                                                                      val={emailTranslation[currentLang]}
                                                                      error={errorFlag}
                                                                      errorMessage={errorId[emailKeys]}
                                                                      onBlur={e => {
                                                                          isFirstRender ? handleBlurEvent(e, i) : null;
                                                                      }}
                                                                  />
                                                                  {showEnglishUsText(emailTranslation[Default_Lang])}
                                                              </FlexColumnGap>
                                                          </FlexStartGap>
                                                      );
                                                  }
                                              })
                                            : null}
                                    </FlexColumnGap>
                                </WordingDiv>
                            ) : null}
                        </FlexColumn>
                    </Flex>
                </Paper>
            </EditSurveyDiv>

            {showErrWarning ? (
                <ShowMessage
                    type={'error'}
                    message={'Please fill all required fields'}
                    open={showErrWarning}
                    close={() => setShowErrWarning(false)}
                />
            ) : null}
        </Fragment>
    );
};

export const EditSurveyDiv = styled.div`
    padding-left: ${pxToVw(52)}vw;
    padding-top: ${pxToVh(40)}vh;
    padding-right: ${pxToVh(25)}vw;
    padding-bottom: ${pxToVh(40)}vh;
    width: 100%;
    height: auto;
    margin-top: 28vh;
    .langBar {
        align-items: flex-start !important;
        align-self: stretch;
        width: ${pxToVw(212)}vw;
        border-right: 1px solid ${colorsCode.Neutral10};
        cursor: pointer;
    }
    .title {
        align-items: center !important;
        padding: ${pxToVh(21)}vh ${pxToVw(16)}vw;
        width: ${pxToVw(212)}vw;
        border-bottom: 1px solid ${colorsCode.Neutral10};
    }
    .languageClass {
        align-self: stretch;
        padding: ${pxToVh(21)}vh ${pxToVw(16)}vw;
        border-bottom: 1px solid ${colorsCode.Neutral10};
    }
    .boldText {
        font-weight: 500;
    }
`;

export const WordingDiv = styled.div`
    padding: ${pxToVh(40)}vh ${pxToVw(40)}vw;
    border-bottom: 1px solid ${colorsCode.Neutral10};
    overflow: hidden;
`;

export const MainContent = styled.div`
    padding: ${pxToVh(24)}vh ${pxToVw(48)}vw;
    background: ${colorsCode.SecondaryAmber100};
`;

const InfoDiv = styled.div`
    background: ${colorsCode.Orange};
    padding: ${pxToVh(6)}vh ${pxToVw(12)}vw;
    border-radius: 4px;

    ul {
        padding-left: ${pxToVw(21)}vw;
    }
`;

const EnglishText = styled.span`
    font-size: ${pxToVh(12)}vh;
    color: ${colorsCode.TextSubtle};
`;

export default Wording;
