import { createAsyncThunk } from '@reduxjs/toolkit';
import { Endpoints } from '../../../http/Endpoints';
import { api } from '../../../http/api';
import { MESSAGE } from '../../../global-constants';

export const getQuestionScales = createAsyncThunk('/api/scale-listing', async ({ surveyId }, { rejectWithValue }) => {
    try {
        const response = await api.get(Endpoints.getQtnScale.replace(':survey_id', surveyId));
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const getAgreementFrequencyQues = createAsyncThunk(
    '/api/additonal-ques',
    async ({ surveyId, type, scaleId }, { rejectWithValue }) => {
        try {
            const url = Endpoints.getQuestions
                .replace(':survey_id', surveyId)
                .concat(`?question_type=ADDITIONAL&is_custom=true&question_scale_id=${scaleId}`);
            const response = await api.get(url);
            const { data } = response;
            return { data, type };
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const updateAgreementFrequencyQues = createAsyncThunk(
    '/api/additonal-ques-update',
    async ({ surveyId, questionId, requestParams }, { rejectWithValue }) => {
        try {
            const url = Endpoints.updateAdditionalQues
                .replace(':survey_id', surveyId)
                .replace(':question_id', questionId);
            const response = await api.put(url, { ...requestParams });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const updateValuesSecondaryInstructions = createAsyncThunk(
    '/api/update-values-secondary-instructions',
    async ({ surveyid, groupId, requestParams }, { rejectWithValue }) => {
        try {
            const url = Endpoints.ohiTemplateSubGroupQuestionPut
                .replace(':survey_id', surveyid)
                .replace(':question_id', groupId);
            const response = await api.put(url, { ...requestParams });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const updateInstructionsTextSubGroup = createAsyncThunk(
    'updateInstructionsTextSubGroup',
    async ({ surveyid, groupId, requestParams }, { rejectWithValue }) => {
        try {
            const url = Endpoints.ohiTemplateSubGroupQuestionPut
                .replace(':survey_id', surveyid)
                .replace(':question_id', groupId);
            const response = await api.put(url, { ...requestParams });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const updateAgreementFrequencyInstructions = createAsyncThunk(
    '/api/additonal-ques-instruction-update',
    async ({ surveyid, groupId, requestParams }, { rejectWithValue }) => {
        try {
            const url = Endpoints.ohiTemplateSubGroupQuestionPut
                .replace(':survey_id', surveyid)
                .replace(':question_id', groupId);
            const response = await api.put(url, { ...requestParams });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const getOpenEndStandardQuestions = createAsyncThunk(
    '/api/opentext-listing',
    async ({ surveyId }, { rejectWithValue }) => {
        try {
            const response = await api.get(
                Endpoints.getStandardDemo.replace(':survey_id', surveyId).concat('?question_type=OPEN_TEXT')
            );
            const { data } = response;
            return { data };
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const getOpenEndCustomQuestions = createAsyncThunk(
    '/api/opentextcustom-listing',
    async ({ surveyId }, { rejectWithValue }) => {
        try {
            const response = await api.get(
                Endpoints.getStandardDemo
                    .replace(':survey_id', surveyId)
                    .concat('?question_type=OPEN_TEXT&is_custom=true')
            );
            const { data } = response;
            return { data };
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const updateOpenEndQues = createAsyncThunk(
    '/api/updateopentext',
    async ({ surveyId, questionId, requestParams, isStandard }, { rejectWithValue }) => {
        try {
            const commonUrl = Endpoints.updateCustomDemo.replace(':survey_id', surveyId);
            const url = isStandard
                ? Endpoints.ohiTemplateQuestionPut.replace(':survey_id', surveyId).replace(':question_id', questionId)
                : commonUrl.replace(':question_id', questionId).concat('?question_type=OPEN_TEXT');
            const response = await api.put(url, { ...requestParams });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const updateQuestionModulesQuestion = createAsyncThunk(
    'updateQuestionModulesQuestion',
    async ({ surveyId, questionId, requestParams, isStandard }, { rejectWithValue }) => {
        try {
            const commonUrl = Endpoints.updateCustomDemo.replace(':survey_id', surveyId);
            const url = isStandard
                ? Endpoints.ohiTemplateQuestionPut.replace(':survey_id', surveyId).replace(':question_id', questionId)
                : commonUrl.replace(':question_id', questionId).concat('?question_type=OPEN_TEXT');
            const response = await api.put(url, { ...requestParams });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const getOhiTemplateQuestions = createAsyncThunk(
    '/api/getOHItemplateQuestions',
    async ({ surveyId }, { rejectWithValue }) => {
        try {
            const url = Endpoints.ohiTemplateQuestionGet.replace(':survey_id', surveyId);
            const response = await api.get(url);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const getOutcomesAndPractices = createAsyncThunk(
    '/api/getOutcomesAndPractices',
    async ({ surveyId }, { rejectWithValue }) => {
        try {
            const url = Endpoints.ohiTemplateQuestionGet
                .replace(':survey_id', surveyId)
                .concat('?is_all_questions=true');
            const response = await api.get(url);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const updateOhiTemplateQuestions = createAsyncThunk(
    '/api/updateOHItemplateQuestions',
    async ({ surveyId, questionId, requestParams, flag }, { rejectWithValue }) => {
        try {
            let url =
                flag === 'questions' ? Endpoints.ohiTemplateQuestionPut : Endpoints.ohiTemplateSubGroupQuestionPut;
            url = url.replace(':survey_id', surveyId).replace(':question_id', questionId);
            const response = await api.put(url, { ...requestParams });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const getAdditionalModulesData = createAsyncThunk(
    'additionalmodule',
    async ({ surveyid }, { rejectWithValue }) => {
        try {
            let url = Endpoints.additionalModule.replace(':survey_id', surveyid);
            const response = await api.get(url);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

//Update instruction text in additional module
export const updateInstructionText = createAsyncThunk(
    'updateinstruction',
    async ({ surveyid, groupId, requestParams }, { rejectWithValue }) => {
        try {
            const url = Endpoints.updateInstructions
                .replace(':survey_id', surveyid)
                .replace(':ques_sub_group_id', groupId);
            const response = await api.put(url, { ...requestParams });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const updateInstructionTextPage = createAsyncThunk(
    'updateInstructionTextPage',
    async ({ surveyid, groupId, requestParams }, { rejectWithValue }) => {
        try {
            const url = Endpoints.updateInstructions
                .replace(':survey_id', surveyid)
                .replace(':ques_sub_group_id', groupId);
            const response = await api.put(url, { ...requestParams });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const updateInstructionOpenText = createAsyncThunk(
    'updateinstructionOpenText',
    async ({ surveyid, groupId, requestParams }, { rejectWithValue }) => {
        try {
            const url = Endpoints.updateInstructions
                .replace(':survey_id', surveyid)
                .replace(':ques_sub_group_id', groupId);
            const response = await api.put(url, { ...requestParams });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const getTextEditTabs = createAsyncThunk(
    'getTextEditTabs',
    async ({ survey_id, group_type }, { rejectWithValue }) => {
        try {
            const url = Endpoints.getTextEditTabs.replace(':survey_id', survey_id).replace(':group_type', group_type);
            const response = await api.get(url);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const getTextEditTabsData = createAsyncThunk(
    'getTextEditTabsData',
    async ({ survey_id, sub_group, group_type }, { rejectWithValue }) => {
        try {
            const url = Endpoints.getTextEditTabsData
                .replace(':survey_id', survey_id)
                .replace(':group_type', group_type)
                .replace(':sub_group', sub_group);
            const response = await api.get(url);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const updateSystemMessages = createAsyncThunk(
    'updateSystemMessages',
    async ({ survey_id, requestParams, isScales = false }, { rejectWithValue }) => {
        const updatedEndpoint = isScales ? Endpoints.updateScaleQuestion : Endpoints.getSurveyOverview;
        try {
            const url = updatedEndpoint.replace(':survey_id', survey_id);
            const response = await api.put(url, requestParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);
