import { createSlice } from '@reduxjs/toolkit';
import {
    GetClosingProtocolRawData,
    GetClosingProtocol,
    GetSalesForceClosingProtocol,
    PutClosingProtocol,
    SearchSalesForceClosingProtocol,
    PostSalesForceClosingProtocol,
} from '../../actions/ClosingProtocol/actions';
import { UpdateLegalCheckDatabase } from '../../actions/Client/actions';

const OPTIONS_INITIAL_STATE = {
    population: [],
    single_multiple_country: [],
    countries: [],
    org_science_info: [],
    priority_practice: [],
    priority_practices_list: [],
    workshop_info: [],
    legal_agreements: [],
    demographics: [],
    client_recipes: [],
    sub_population: [],
    benchmarking_dropdown_list: [],
};

const DATA_INITIAL_STATE = {
    client_recipes: [],
    priority_practice: '',
    priority_practices_list: [],
    workshop_info: [],
    cohesion_demo: '',
    org_science_info: '',
    org_science_other_text: '',
    non_standard_info: '',
    future_info: '',
    repo_b_link: '',
    verification_check: false,
    legal_agreements: [],
    supported_countries: [],
    population: '',
    sub_population: '',
    sub_population_specify: '',
    sample_value: 0,
    is_multiple_countries: '',
    workshop_other_text: '',
    sensitive_demos: [],
    is_resurvey: false,
    survey_data: {
        salesforce_id: '',
        total_respondents: 0,
        survey_meta: [
            {
                meta_key: 'survey_owners',
                meta_value: '',
            },
            {
                meta_key: 'supported_languages',
                meta_value: '',
            },
            {
                meta_key: 'survey_analysts',
                meta_value: '',
            },
            {
                meta_key: 'supported_project_managers',
                meta_value: '',
            },
            {
                meta_key: 'exclude_languages',
                meta_value: '',
            },
        ],
    },
};

const SALESFORCE_INITIAL_DATA = {
    opportunity_client_name: '',
    opportunity_name: '',
    sector_level_1: '',
    sector_level_2: '',
    legal_check: false,
    geo_restriction: '',
    data_restriction: '',
    benchmarking_allowed: false,
    expert_email: '',
    expert_name: '',
};
export const INTITIAL_STATE = {
    loading: false,
    data: DATA_INITIAL_STATE,
    benchmarking_allowed: '',
    localData: {
        client_id: '',
        client_name: '',
        previous_survey_id: '',
        language: '',
        launch_date: '',
        completes: 0,
    },
    salesforce: SALESFORCE_INITIAL_DATA,
    error: false,
    errorMessage: '',
    success: false,
    successMessage: '',
    options: OPTIONS_INITIAL_STATE,
    optionsLoaded: false,
    valuesLoaded: false,
    salesforce_linked: false,
    salesforce_updated: false,
    update_flag: true,
    putClosingProtocolSuccess: false,
};

const optionCreator = (data, state) => {
    Object.keys(data || {}).map(el => state.push({ uuid: el, value: el, name: data[el] }));
};

const ClosingProtocolSlice = createSlice({
    name: 'ClosingProtocol',
    initialState: INTITIAL_STATE,
    extraReducers: builder => {
        builder
            //for raw data
            .addCase(GetClosingProtocolRawData.pending, state => {
                state.loading = true;
                state.error = false;
                state.options = OPTIONS_INITIAL_STATE;
            })
            .addCase(GetClosingProtocolRawData.fulfilled, (state, { payload }) => {
                state.loading = false;
                optionCreator(payload.data?.population, state.options.population);
                optionCreator(payload.data?.single_multiple_country, state.options.single_multiple_country);
                optionCreator(payload.data?.org_science_info, state.options.org_science_info);
                optionCreator(payload.data?.priority_practice, state.options.priority_practice);
                optionCreator(payload.data?.priority_practices_list, state.options.priority_practices_list);
                optionCreator(payload.data?.workshop_info, state.options.workshop_info);
                optionCreator(payload.data?.legal_agreements, state.options.legal_agreements);
                optionCreator(payload.data?.demographics, state.options.demographics);
                optionCreator(payload.data?.client_recipes, state.options.client_recipes);
                optionCreator(payload.data?.sub_population, state.options.sub_population);
                optionCreator(payload.data?.benchmarking_allowed, state.options.benchmarking_dropdown_list);
                state.options.countries = payload.data?.countries.map(el => {
                    return { uuid: el.key, name: el.value, ...el };
                });
                state.optionsLoaded = true;
            })
            .addCase(GetClosingProtocolRawData.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload || 'Error GetClosingProtocolRawData    ';
            })
            //for pullind data for closing protocols
            .addCase(GetClosingProtocol.pending, state => {
                state.loading = true;
                state.error = false;
            })
            .addCase(GetClosingProtocol.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.localData.previous_survey_id = payload?.data?.previous_survey_id;
                state.localData.client_id = payload?.data?.client_id;
                state.localData.client_name = payload?.data?.client_name;
                state.localData.completes = payload?.data?.completes;
                state.update_flag = payload?.data?.update_flag;
                state.benchmarking_allowed = payload?.data?.benchmarking_allowed;
                state.valuesLoaded = true;

                const filledData = Object.fromEntries(
                    Object.entries(payload?.data).filter(
                        ([key, value]) => value !== null && key && key !== 'update_flag'
                    )
                );
                delete filledData?.previous_survey_id;
                delete filledData?.client_id;
                delete filledData?.client_name;
                delete filledData?.completes;

                state.data = { ...state.data, ...filledData };
            })
            .addCase(GetClosingProtocol.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload || 'GetClosingProtocol';
            })
            // for getting data from sales force with salesforceid
            .addCase(GetSalesForceClosingProtocol.pending, state => {
                state.loading = true;
                state.error = false;
            })
            .addCase(GetSalesForceClosingProtocol.fulfilled, (state, { payload }) => {
                state.loading = false;
                if (payload.data[0] !== undefined) {
                    state.salesforce = payload?.data[0];
                }
            })
            .addCase(GetSalesForceClosingProtocol.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload || 'Error GetSalesForceClosingProtocol';
            })
            //for saving closing protocols data
            .addCase(PutClosingProtocol.pending, state => {
                state.loading = true;
                state.error = false;
            })
            .addCase(PutClosingProtocol.fulfilled, state => {
                state.loading = false;
                state.successMessage = 'Survey details updated successfully.';
                state.success = true;
                state.salesforce_linked = false;
                state.putClosingProtocolSuccess = true;
            })
            .addCase(PutClosingProtocol.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload || 'PutClosingProtocol';
                state.salesforce_linked = false;
            })
            //for searching sales force opportunity
            .addCase(SearchSalesForceClosingProtocol.pending, state => {
                state.salesforceLoading = true;
                state.error = false;
            })
            .addCase(SearchSalesForceClosingProtocol.fulfilled, (state, { payload }) => {
                state.salesforceLoading = false;
                state.salesforce = payload?.data;
                state.salesforce_updated = true;
                // state.success = true;
                // state.successMessage = 'Success SearchSalesForceClosingProtocol';
            })
            .addCase(SearchSalesForceClosingProtocol.rejected, state => {
                state.salesforceLoading = false;
                state.salesforce = SALESFORCE_INITIAL_DATA;
                state.salesforce_updated = false;
                state.data.survey_data.salesforce_id = '';
                state.error = true;
                state.errorMessage =
                    'The opportunity name not matching with the database, please reach out to an expert.';
            })

            .addCase(UpdateLegalCheckDatabase.pending, state => {
                state.salesforceLoading = true;
                state.error = false;
            })
            .addCase(UpdateLegalCheckDatabase.fulfilled, (state, { payload }) => {
                state.salesforceLoading = false;
                state.salesforce = payload?.data;
                state.salesforce_updated = true;
                // state.success = true;
                // state.successMessage = 'Success SearchSalesForceClosingProtocol';
            })
            .addCase(UpdateLegalCheckDatabase.rejected, state => {
                state.salesforceLoading = false;
                state.salesforce = SALESFORCE_INITIAL_DATA;
                state.salesforce_updated = false;
                state.data.survey_data.salesforce_id = '';
                state.error = true;
                state.errorMessage =
                    'The opportunity name not matching with the database, please reach out to an expert.';
            })

            //for saving data in salesforce and getting salesforce id
            .addCase(PostSalesForceClosingProtocol.pending, state => {
                state.loading = true;
                state.error = false;
            })
            .addCase(PostSalesForceClosingProtocol.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.data.survey_data.salesforce_id = payload?.data[0].salesforce_id;
                state.salesforce_linked = true;
                state.salesforce_updated = false;
                // state.success = true;
                // state.successMessage = 'Success PostSalesForceClosingProtocol';
            })
            .addCase(PostSalesForceClosingProtocol.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload || 'Error PostSalesForceClosingProtocol';
            });
    },
    reducers: {
        resetData: state => {
            state.loading = false;
            state.errorMessage = '';
            state.successMessage = '';
            state.error = false;
            state.data = DATA_INITIAL_STATE;
            state.options = OPTIONS_INITIAL_STATE;
            state.optionsLoaded = false;
            state.valuesLoaded = false;
            state.salesforce = SALESFORCE_INITIAL_DATA;
            state.salesforce_linked = false;
            state.salesforce_updated = false;
            state.putClosingProtocolSuccess = false;
        },
        resetErrorState: state => {
            state.loading = false;
            state.errorMessage = '';
            state.successMessage = '';
            state.error = false;
            state.success = false;
        },
        setData: (state, { payload }) => {
            const { name, value } = payload;
            state.data[name] = value;
        },
        setBenchmarkingValue: (state, { payload }) => {
            state.benchmarking_allowed = payload.val;
        },
        setSalesforce: (state, { payload }) => {
            const { name, value } = payload;
            state.salesforce[name] = value;
        },
        setLocalData: (state, { payload }) => {
            const { name, value } = payload;
            state.localData[name] = value;
        },
        setDataArray: (state, { payload }) => {
            const { name, value } = payload;
            if (state.data[name]?.length) {
                state.data[name] = [...state.data[name], value];
            } else {
                state.data[name] = [value];
            }
        },
        removeDataArray: (state, { payload }) => {
            const { name, value } = payload;
            const newData = state.data[name].filter(el => el !== value);
            state.data[name] = newData;
        },
        setSurveyData: (state, { payload }) => {
            const { name, value } = payload;
            if (name === 'salesforce_id') {
                state.data.survey_data.salesforce_id = value;
            } else if (name === 'total_respondents') {
                state.data.survey_data.total_respondents = value;
            } else {
                state.data.survey_data.survey_meta.forEach(({ meta_key }, i) => {
                    if (meta_key === name && meta_key === 'survey_owners') {
                        let owner_uuids = '';
                        value.forEach(({ uuid }) => {
                            owner_uuids = owner_uuids + `${uuid},`;
                        });
                        state.data.survey_data.survey_meta[i]['meta_value'] = owner_uuids;
                    } else if (meta_key === name) {
                        state.data.survey_data.survey_meta[i]['meta_value'] = value;
                    }
                });
            }
        },
    },
});

export const {
    resetData,
    resetErrorState,
    setData,
    setBenchmarkingValue,
    setSalesforce,
    setDataArray,
    removeDataArray,
    setLocalData,
    setSurveyData,
} = ClosingProtocolSlice.actions;
export default ClosingProtocolSlice.reducer;
