import React, { useEffect, useState } from 'react';
import { ListContainer, ModalContainer } from './styles';
import Text from '../../../components/KnowledgeCenter/Text';
import { FlexColumn, FlexColumnGap } from '../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import Button from '../../../components/KnowledgeCenter/Button';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../global-constants';
import { useSelector, useDispatch } from 'react-redux';
import { FlexRowGap } from '../../../components/Dashboard/partials/AllSurveys';
import { UserTemplates } from '../../../store/actions/Surveys/action';
import { useLocation } from 'react-router-dom';
import { VERSION_PAGE_DATA } from '../../../utils/kc-data';
import { EXCLUDE_VERSIONS } from '../../../utils/kc-constants';

const VersionList = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const location = useLocation();
    const { search } = location;
    const params = new URLSearchParams(search);
    const { data = [] } = useSelector(state => state.userTemplates);
    const { knowledge_centre_version = '' } = data[0] || {};
    const [uniqueVersions, setUniqueVersions] = useState([]);
    const url =
        params.get('deep_link') === '1'
            ? `${PATHS.KNOWLEDGE_CENTER_HOME}?deep_link=1&version=${knowledge_centre_version}`
            : PATHS.KNOWLEDGE_CENTER_HOME + `?version=${knowledge_centre_version}`;

    useEffect(() => {
        const apiCall = async () => {
            if (!data.length) {
                await dispatch(UserTemplates());
            } else if (data.length === 1) {
                history(url);
            } else if (data.length > 1) {
                const uniqueVersions = [];
                data.forEach(obj => {
                    const { knowledge_centre_version } = obj;
                    if (
                        !uniqueVersions.some(o => o.knowledge_centre_version === knowledge_centre_version) &&
                        !EXCLUDE_VERSIONS.includes(knowledge_centre_version)
                    ) {
                        uniqueVersions.push({ ...obj });
                    }
                });
                setUniqueVersions(uniqueVersions);
                if (uniqueVersions.length == 1) {
                    history(url);
                }
            }
        };
        apiCall();
    }, [data]);

    return uniqueVersions.length === 1 ? null : (
        <ListContainer>
            <FlexColumnGap gap={pxToVh(60)}>
                <FlexColumn style={{ alignItems: 'center', alignSelf: 'center' }}>
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={44}
                        lineHeight={52}
                        fontWeight={500}
                        label={VERSION_PAGE_DATA.title}
                    ></Text>
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={18}
                        lineHeight={28}
                        fontWeight={400}
                        label={VERSION_PAGE_DATA.sub_title}
                    ></Text>
                </FlexColumn>
                <FlexRowGap gap={pxToVw(30)} style={{ width: '70%', alignItems: 'stretch', alignSelf: 'center' }}>
                    {uniqueVersions.map(obj => (
                        <ModalContainer key={obj.id} backgroundColor={colorsCode.yellow}>
                            <FlexColumnGap gap={pxToVh(20)}>
                                <Text
                                    fontColor={colorsCode.TextHeading}
                                    fontSize={24}
                                    lineHeight={28}
                                    fontWeight={500}
                                    label={VERSION_PAGE_DATA[obj?.knowledge_centre_version.split('.')[0]]?.title}
                                ></Text>
                                <div style={{ height: '130px' }}>
                                    <Text
                                        fontColor={colorsCode.TextHeading}
                                        fontSize={14}
                                        lineHeight={20}
                                        fontWeight={300}
                                        maxLines={4}
                                        label={
                                            VERSION_PAGE_DATA[obj.knowledge_centre_version.split('.')[0]]?.description
                                        }
                                    ></Text>
                                </div>
                                <div style={{ marginTop: pxToVh(32) }}>
                                    <Button
                                        fontWeight={400}
                                        onClick={() => {
                                            console.log('version picked');
                                            const isDeepLink = params.get('deep_link') === '1';
                                            if (isDeepLink)
                                                history(
                                                    PATHS.KNOWLEDGE_CENTER_HOME +
                                                        '?deep_link=1' +
                                                        `&version=${obj?.knowledge_centre_version}`
                                                );
                                            else
                                                history(
                                                    PATHS.KNOWLEDGE_CENTER_HOME +
                                                        `?version=${obj?.knowledge_centre_version}`
                                                );
                                        }}
                                        label={'Choose'}
                                    ></Button>
                                </div>
                            </FlexColumnGap>
                        </ModalContainer>
                    ))}
                </FlexRowGap>
            </FlexColumnGap>
        </ListContainer>
    );
};

export default VersionList;
