import React, { useState, Fragment } from 'react';
import { CTA, FlexBetween, FlexRow, FlexStartGap, StyledFilterMenu } from '../../../../common/styled/styled';
import SearchBar from '../../../../common/FormInputs/SearchBar';
import DateRangePicker from '../../../../common/FormInputs/DateRangePicker';
import FilterIcon from '../../../../assets/img/filter.svg';
import ExportIcon from '../../../../assets/img/download-black.svg';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { ACTUAL_STATUS, LABELS, SURVEY_STATUS_UI } from '../../../../global-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import FilterModal from '../../../../common/FormInputs/FilterModal';
import UserthemeStyles from '../../../User/themes';
import { commonThemes } from '../../../../common/themes';
import { ReactComponent as ArrowDown } from '../../../../assets/img/arrow_down.svg';
import { SurveyCloseDialog } from '../../../../common/DialogModal';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getLinkTypesLabel } from '../../../../utils/functions';
import { cloneDeep } from 'lodash';
import { GetAllSurveyListAction, GetAllSurveys } from '../../../../store/actions/Surveys/action';
import { CommonLabel, Img, SwitchOnOff } from '../../../../common/FormInputs/Common';
import CountLabel from '../../../../common/FormInputs/CountLabel';

const DashboardFilters = ({
    onSearchFn,
    OnDateChangeFn,
    handleCloseSurvey,
    tab,
    dispatchAction,
    allState,
    filters,
    setFilters,
    selectedIds = [],
    setAllState,
    filtersObj,
    setFiltersObj,
    handleClearFilters,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    exportAllSurvey,
    setShowTest,
}) => {
    const [filterModalOpen, setFilterModalOpen] = useState(null);
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const styles = UserthemeStyles();

    const onCloseSurvey = () => {
        setShowCloseConfirmation(false);
        handleCloseSurvey();
    };
    return (
        <Fragment>
            <div style={{ width: '100%' }}>
                <FlexBetween>
                    <FlexStartGap gap={pxToVh(20)}>
                        <SearchBar
                            placeholder="Search"
                            id="search"
                            name="search"
                            onChangeFn={onSearchFn}
                            val={allState.searchParam}
                            callApi={true}
                            tab={tab}
                        />
                        <DateRangePicker
                            OnDateChangeFn={OnDateChangeFn}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                        />
                        <FlexRow
                            onClick={event => {
                                setExpanded(false);
                                setFilterModalOpen(event.currentTarget);
                            }}
                            className={`${styles.filterTxt}`}
                        >
                            <Img image={FilterIcon} h={pxToVh(16)} w={pxToVw(16)} alt="filter icon" />
                            <CommonLabel
                                textSize={14}
                                textWeight={400}
                                textColor={colorsCode.PrimaryDeepBlue}
                                lineHeight={20}
                                label={LABELS.FILTER}
                                cursor="pointer"
                            />
                        </FlexRow>
                        <FlexRow
                            onClick={() => {
                                exportAllSurvey();
                            }}
                            className={`${styles.filterTxt}`}
                        >
                            <Img image={ExportIcon} h={pxToVh(16)} w={pxToVw(16)} alt="filter icon" />
                            <CommonLabel
                                textSize={14}
                                textWeight={400}
                                textColor={colorsCode.PrimaryDeepBlue}
                                lineHeight={20}
                                label="Export"
                                cursor="pointer"
                            />
                        </FlexRow>
                    </FlexStartGap>
                    <CTA
                        bg={colorsCode.white}
                        color={colorsCode.PrimaryDeepBlue}
                        pt={6}
                        pl={16}
                        bcolor={colorsCode.PrimaryDeepBlue}
                        onClick={() => setShowCloseConfirmation(true)}
                        disabled={!selectedIds?.length}
                        style={{ opacity: 1 }}
                    >
                        {LABELS.DASHBOARD.CloseSurvey}
                    </CTA>

                    <DashboardFilterModal
                        cancelBtnName={LABELS.FILTER_MODAL.CANCEL_BTN}
                        saveBtnName={LABELS.FILTER_MODAL.APPLY_BTN}
                        mainTitle={LABELS.FILTER_MODAL.MAIN_TITLE}
                        clearTitle={LABELS.FILTER_MODAL.CLEAR_TITLE}
                        open={Boolean(filterModalOpen)}
                        close={() => setFilterModalOpen(null)}
                        anchorEl={filterModalOpen}
                        tab={tab}
                        dispatchAction={dispatchAction}
                        allState={allState}
                        filters={filters}
                        setFilters={setFilters}
                        setAllState={setAllState}
                        setExpanded={setExpanded}
                        expanded={expanded}
                        filtersObj={filtersObj}
                        setFiltersObj={setFiltersObj}
                        handleClearFilters={handleClearFilters}
                        setShowTest={setShowTest}
                    />
                </FlexBetween>
            </div>
            {showCloseConfirmation ? (
                <SurveyCloseDialog
                    open={showCloseConfirmation}
                    onClose={() => setShowCloseConfirmation(false)}
                    onActionPressed={onCloseSurvey}
                />
            ) : null}
        </Fragment>
    );
};

export const DashboardFilterModal = ({
    open,
    cancelBtnName,
    saveBtnName,
    mainTitle,
    clearTitle,
    close,
    tab,
    dispatchAction,
    allState,
    filters,
    setFilters,
    anchorEl = null,
    setAllState,
    expanded,
    setExpanded,
    filtersObj,
    setFiltersObj,
    handleClearFilters,
    setShowTest,
}) => {
    const { template_type } = filters;
    const keys = Object.keys(SURVEY_STATUS_UI);
    const { statusList, typesList, languages, localShowTest, surveyTemplatesList, maintenanceModeList } = filtersObj;

    const handleAccordion = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const onChangeMaintenance = val => {
        setFilters({ ...filters, maintenance_mode: val });
    };

    const clearFilters = () => {
        close();
        const copy = {
            status: [],
            link_type: [],
            template_type: [],
            survey_language: [],
            is_test: false,
            maintenance_mode: 'Both',
        };
        if (tab === 1) {
            dispatchAction(GetAllSurveyListAction({ ...allState, reqBody: { ...copy } }));
        } else {
            dispatchAction(
                GetAllSurveys({
                    ...allState.dateRange,
                    limit: allState.limit,
                    page: allState.page,
                    q: allState.searchParam,
                    reqBody: { ...copy },
                })
            );
        }
        setShowTest(false);
        handleClearFilters();
    };

    const handleChange = (e, i, type) => {
        const {
            target: { checked },
        } = e;
        let copy;

        if (type === 'link_type') {
            copy = cloneDeep(typesList);
            copy[i].is_selected = checked;
            setFiltersObj({
                ...filtersObj,
                typesList: copy,
            });
        } else if (type === 'status') {
            copy = cloneDeep(statusList);
            copy[i].is_selected = checked;
            setFiltersObj({
                ...filtersObj,
                statusList: copy,
            });
        } else if (type === 'survey_language') {
            copy = cloneDeep(languages);
            copy[i].is_selected = checked;
            setFiltersObj({
                ...filtersObj,
                languages: copy,
            });
        } else if (type === 'template_type') {
            copy = cloneDeep(surveyTemplatesList);
            copy[i].is_selected = checked;
            setFiltersObj({
                ...filtersObj,
                surveyTemplatesList: copy,
            });
        }
        const selectedVals = copy
            .filter(item => item.is_selected)
            .map(item =>
                type === 'survey_language' || type === 'template_type'
                    ? item.uuid
                    : item.name === 'UNDER REVIEW'
                    ? 'UNDER_REVIEW'
                    : item.name
            );
        const updatedFilters = { ...filters, [type]: [...selectedVals] };

        if (type === 'link_type') {
            const linkTypeFilters = updatedFilters.link_type;
            delete updatedFilters.is_combined_survey;

            let isCombinedSelected = linkTypeFilters.indexOf('INDIVIDUAL_PLUS_GENERIC');
            let isIndividualSelected = linkTypeFilters.find(type => type === 'INDIVIDUAL');

            if (isIndividualSelected && isCombinedSelected === -1) {
                updatedFilters.is_combined_survey = false;
            }
            if (isCombinedSelected !== -1) {
                // which means it is selected
                updatedFilters.is_combined_survey = true;
                linkTypeFilters.splice(isCombinedSelected, 1);
                /* For BE there is no 'INDIVIDUAL_PLUS_GENERIC' survey
                so we use the key is_combined_survey*/
            }
        }
        setFilters(updatedFilters);
    };

    const applyFilters = () => {
        const updatedFilters = cloneDeep(filters);
        let tempMaintenanceMode = filters.maintenance_mode;
        if (tempMaintenanceMode != 'Both') {
            tempMaintenanceMode = tempMaintenanceMode === 'ENABLE';
        }
        close();
        setShowTest(updatedFilters.is_test);
        if (tab === 1) {
            dispatchAction(
                GetAllSurveyListAction({
                    ...allState,
                    reqBody: { ...updatedFilters, maintenance_mode: tempMaintenanceMode },
                    page: 1,
                })
            );
        } else {
            dispatchAction(
                GetAllSurveys({
                    ...allState.dateRange,
                    limit: allState.limit,
                    page: 1,
                    q: allState.searchParam,
                    reqBody: { ...updatedFilters, maintenance_mode: tempMaintenanceMode },
                })
            );
        }
        setAllState({ ...allState, page: 1 });
    };

    const styles = commonThemes();

    return (
        <StyledFilterMenu
            open={open}
            className={styles.ContentWrapper}
            anchorEl={anchorEl}
            getcontentanchorel={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            height={`${pxToVh(400)}vh`}
            sx={{
                marginLeft: `${pxToVw(120)}vw`,
            }}
            disableEscapeKeyDown
        >
            <FlexBetween>
                <DialogTitle className={styles.filterModalTitle}>{mainTitle}</DialogTitle>
                <DialogTitle onClick={clearFilters} className={`${styles.filterModalClearTitle}`}>
                    {clearTitle}
                </DialogTitle>
            </FlexBetween>
            <DialogContent className={styles.filterOptionsContainer}>
                <DialogContentText className={styles.accordianTitle}>
                    <TestFilterContainer>
                        <FlexBetweenWrap>
                            <CommonLabel
                                textWeight={500}
                                textSize={14}
                                textColor={colorsCode.Neutral80}
                                lineHeight={20}
                                label={LABELS.FILTER_MODAL.SHOW_TEST_SURVEY}
                            />
                            <SwitchOnOff
                                name="isTest"
                                id="isTest"
                                onChangeHandler={e => {
                                    setFiltersObj({ ...filtersObj, localShowTest: e.target.checked });
                                    setFilters({ ...filters, is_test: e.target.checked });
                                }}
                                checkedFlag={localShowTest}
                            />
                        </FlexBetweenWrap>
                    </TestFilterContainer>
                    <Accordion
                        square={true}
                        disableGutters={true}
                        elevation={0}
                        expanded={expanded === 'templates'}
                        onChange={handleAccordion('templates')}
                        sx={{
                            borderBottom: `1px solid ${colorsCode.Neutral10}`,
                        }}
                    >
                        <AccordionSummary expandIcon={<ArrowDown />} aria-controls="panel1a-content" id="templates">
                            <FlexBetweenWrap>
                                <CommonLabel
                                    textWeight={500}
                                    textSize={14}
                                    textColor={colorsCode.Neutral80}
                                    lineHeight={20}
                                    label={LABELS.FILTER_MODAL.SURVEY_TYPE}
                                />
                                <CountLabel count={template_type?.length} />
                            </FlexBetweenWrap>
                        </AccordionSummary>
                        <AccordionDetails>
                            {surveyTemplatesList.map((item, i) => {
                                return (
                                    <FlexRow key={i}>
                                        <Checkbox
                                            checked={item.is_selected}
                                            sx={{
                                                height: '4.38vh',
                                                width: '4.38vh',
                                                color: colorsCode.Neutral30,
                                            }}
                                            checkedIcon={<CheckBoxIcon sx={{ color: '#000' }} />}
                                            onChange={e => handleChange(e, i, 'template_type')}
                                        />
                                        <CommonLabel
                                            textWeight={400}
                                            textSize={14}
                                            textColor={colorsCode.Neutral80}
                                            lineHeight={20}
                                            label={item.template_name}
                                        />
                                    </FlexRow>
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        square={true}
                        disableGutters={true}
                        elevation={0}
                        expanded={expanded === 'status'}
                        onChange={handleAccordion('status')}
                        sx={{
                            borderBottom: `1px solid ${colorsCode.Neutral10}`,
                        }}
                    >
                        <AccordionSummary expandIcon={<ArrowDown />} aria-controls="panel1a-content" id="status">
                            <FlexBetweenWrap>
                                <CommonLabel
                                    textWeight={500}
                                    textSize={14}
                                    textColor={colorsCode.Neutral80}
                                    lineHeight={20}
                                    label={LABELS.FILTER_MODAL.SURVEY_STATUS}
                                />
                                <CountLabel
                                    count={statusList.filter(({ is_selected }) => is_selected === true).length}
                                />
                            </FlexBetweenWrap>
                        </AccordionSummary>
                        {statusList &&
                            statusList.map((item, i) => {
                                return (
                                    <AccordionDetails key={i}>
                                        <FlexRow>
                                            <Checkbox
                                                checked={item.is_selected}
                                                sx={{
                                                    height: '4.38vh',
                                                    width: '4.38vh',
                                                    color: '#b3b3b3',
                                                }}
                                                checkedIcon={<CheckBoxIcon sx={{ color: '#000' }} />}
                                                onChange={e => handleChange(e, i, 'status')}
                                            />
                                            <CommonLabel
                                                textWeight={400}
                                                textSize={14}
                                                textColor={colorsCode.Neutral80}
                                                lineHeight={20}
                                                label={ACTUAL_STATUS[keys[i]]}
                                            />
                                        </FlexRow>
                                    </AccordionDetails>
                                );
                            })}
                    </Accordion>
                    <Accordion
                        square={true}
                        disableGutters={true}
                        elevation={0}
                        expanded={expanded === 'maintenance'}
                        onChange={handleAccordion('maintenance')}
                        sx={{
                            borderBottom: `1px solid ${colorsCode.Neutral10}`,
                        }}
                    >
                        <AccordionSummary expandIcon={<ArrowDown />} aria-controls="panel1a-content" id="maintenance">
                            <FlexBetweenWrap>
                                <CommonLabel
                                    textWeight={500}
                                    textSize={14}
                                    textColor={colorsCode.Neutral80}
                                    lineHeight={20}
                                    label={LABELS.FILTER_MODAL.MAINTENANCE_MODE}
                                />
                                <CountLabel count="1" />
                            </FlexBetweenWrap>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FlexRow>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="OHI-Pulse Survey"
                                    name="radio-buttons-group"
                                >
                                    {maintenanceModeList &&
                                        maintenanceModeList.map((item, i) => {
                                            return (
                                                <FormControlLabel
                                                    key={i}
                                                    value={item.id}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                color: '#b3b3b3',
                                                                '&.Mui-checked': { color: '#000' },
                                                            }}
                                                            checked={filters.maintenance_mode === item.value}
                                                        />
                                                    }
                                                    label={
                                                        <CommonLabel
                                                            textWeight={400}
                                                            textSize={14}
                                                            textColor={colorsCode.Neutral80}
                                                            lineHeight={20}
                                                            label={item.name}
                                                        />
                                                    }
                                                    onClick={() => onChangeMaintenance(item.value)}
                                                />
                                            );
                                        })}
                                </RadioGroup>
                            </FlexRow>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        square={true}
                        disableGutters={true}
                        elevation={0}
                        expanded={expanded === 'types'}
                        onChange={handleAccordion('types')}
                        sx={{
                            borderBottom: `1px solid ${colorsCode.Neutral10}`,
                        }}
                    >
                        <AccordionSummary expandIcon={<ArrowDown />} aria-controls="panel1a-content" id="types">
                            <FlexBetweenWrap>
                                <CommonLabel
                                    textWeight={500}
                                    textSize={14}
                                    textColor={colorsCode.Neutral80}
                                    lineHeight={20}
                                    label={LABELS.FILTER_MODAL.LINK_TYPE}
                                />
                                <CountLabel
                                    count={typesList.filter(({ is_selected }) => is_selected === true).length}
                                />
                            </FlexBetweenWrap>
                        </AccordionSummary>

                        {typesList &&
                            typesList.map((item, i) => {
                                return (
                                    <AccordionDetails key={i}>
                                        <FlexRow>
                                            <Checkbox
                                                checked={item.is_selected}
                                                sx={{
                                                    height: '4.38vh',
                                                    width: '4.38vh',
                                                    color: '#b3b3b3',
                                                }}
                                                checkedIcon={<CheckBoxIcon sx={{ color: '#000' }} />}
                                                onChange={e => handleChange(e, i, 'link_type')}
                                            />
                                            <CommonLabel
                                                textWeight={400}
                                                textSize={14}
                                                textColor={colorsCode.Neutral80}
                                                lineHeight={20}
                                                label={getLinkTypesLabel(item?.name)}
                                            />
                                        </FlexRow>
                                    </AccordionDetails>
                                );
                            })}
                    </Accordion>
                    <Accordion
                        square={true}
                        disableGutters={true}
                        expanded={expanded === 'languages'}
                        onChange={handleAccordion('languages')}
                    >
                        <AccordionSummary expandIcon={<ArrowDown />} aria-controls="panel1a-content" id="languages">
                            <FlexBetweenWrap>
                                <CommonLabel
                                    textWeight={500}
                                    textSize={14}
                                    textColor={colorsCode.Neutral80}
                                    lineHeight={20}
                                    label={LABELS.FILTER_MODAL.LANGUAGE}
                                />
                                <CountLabel
                                    count={languages.filter(({ is_selected }) => is_selected === true).length}
                                />
                            </FlexBetweenWrap>
                        </AccordionSummary>
                        {languages &&
                            languages.map((item, i) => {
                                return (
                                    <AccordionDetails key={i}>
                                        <FlexRow>
                                            <Checkbox
                                                checked={item.is_selected}
                                                sx={{
                                                    height: '4.38vh',
                                                    width: '4.38vh',
                                                    color: '#b3b3b3',
                                                }}
                                                checkedIcon={<CheckBoxIcon sx={{ color: '#000' }} />}
                                                onChange={e => handleChange(e, i, 'survey_language')}
                                            />
                                            <CommonLabel
                                                textWeight={400}
                                                textSize={14}
                                                textColor={colorsCode.Neutral80}
                                                lineHeight={20}
                                                label={item.language_text}
                                            />
                                        </FlexRow>
                                    </AccordionDetails>
                                );
                            })}
                    </Accordion>
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'flex-end',
                    gap: `${pxToVw(20)}vw`,
                    p: `${pxToVh(20)}vh ${pxToVw(17)}vw ${pxToVh(20)}vh 0vw`,
                    borderTop: `1px solid #ccc`,
                }}
            >
                <CTA
                    color={colorsCode.PrimaryDeepBlue}
                    bcolor={colorsCode.PrimaryDeepBlue}
                    bg={colorsCode.white}
                    onClick={() => {
                        close();
                    }}
                >
                    {cancelBtnName}
                </CTA>
                <CTA
                    color={colorsCode.white}
                    bg={colorsCode.PrimaryDeepBlue}
                    onClick={applyFilters}
                    pt={8}
                    pl={20}
                    ml={20}
                >
                    {saveBtnName}
                </CTA>
            </DialogActions>
        </StyledFilterMenu>
    );
};

const FlexBetweenWrap = styled(FlexBetween)`
    width: 100%;
    margin-right: 0.7vh;
`;

const TestFilterContainer = styled.div`
    padding: 12px 24px;
    border-bottom: 1px solid ${colorsCode?.Neutral10};
`;
FilterModal.propTypes = {
    cancelBtnName: PropTypes.string.isRequired,
    saveBtnName: PropTypes.string.isRequired,
    mainTitle: PropTypes.string.isRequired,
    clearTitle: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};

export default DashboardFilters;
